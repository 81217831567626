* {
  cursor: default;
}

.burger-button {
  font-size: 36px;
  background: none;
  color: rgb(255, 252, 103);
  border: none;
  cursor: pointer;
  position: absolute;
  /* or fixed if desired */
  top: 10px;
  right: 10px;
  padding: 0px 0px;
  /* Adjust to increase background area */
  border-radius: 20px;
  /* Optional: rounded corners */
}

.burger-menu {
  position: absolute;
  top: 55px;
  right: 0;
  background: rgb(255, 252, 103);
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 8px;
}

.burger-menu a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
}

.burger-menu a:hover {
  background: #ffffff;
}

.Home-header {
  background-color: #c02b2b;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(15px + 3vmin);
  color: rgb(255, 252, 103);
  /* font-family: 'Playfair Display', serif; */
  /* font-family: 'Great Vibes', cursive; */
  font-family: 'Dancing Script', cursive;
}

.App-logo-elephant {
  margin-top: 20px; /* Adjust the value as needed */
}


.Home-header1 {
  background-color: #c02b2b;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
  color: rgb(0, 0, 0);
}

.Home-header p,
.Home-header1 p {
  margin: 0;
}

header,
h1 {
    margin: 0;
    padding: 0;
}

.ContactUs-header {
  background-color: #c02b2b;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(15px + 3vmin);
  color: rgb(255, 252, 103);
  /* font-family: 'Playfair Display', serif; */
  /* font-family: 'Great Vibes', cursive; */
  font-family: 'Dancing Script', cursive;
}

.ContactUs-header1 {
  background-color: #c02b2b;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
  color: rgb(0, 0, 0);
}

.ContactUs-header p,
.ContactUs-header1 p {
  margin: 0;
}

.Packages-header {
  background-color: #c02b2b;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(15px + 3vmin);
  color: rgb(255, 252, 103);
  /* font-family: 'Playfair Display', serif; */
  /* font-family: 'Great Vibes', cursive; */
  font-family: 'Dancing Script', cursive;
}

.Packages-header1 {
  background-color: #c02b2b;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
  color: rgb(0, 0, 0);
}

.Packages-header p,
.Packages-header1 p {
  margin: 0;
}

.centered-text {
  display: block; /* Makes the span behave like a block element */
  text-align: center; /* Centers the text within the block */
}
