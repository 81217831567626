* {
  cursor: default;
}

.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-logo-elephant {
  height: 20vmin;
  pointer-events: none;
}

.Home-header {
  display: flex;
  flex-direction: column; /* Stack content vertically (garlands and lamp below the header content) */
  align-items: center;
}

.header-content {
  display: flex; /* Align garlands and text horizontally */
  align-items: center; /* Vertically align garlands and text */
  justify-content: center; /* Center the entire content horizontally */
}

.header-content p {
  margin: 0 10px; /* Add space between the text and the garlands */
  text-align: center;
}

.garland-left {
  height: 80px; /* Adjust the size of the garlands */
  width: auto;
  margin-bottom: 85px;
  margin-right: 10px;
}
.garland-right {
  height: 80px; /* Adjust the size of the garlands */
  width: auto;
  margin-bottom: 85px;
  margin-left: 10px;
}

.App-logo-oillamp {
  height: 12vmin;
  pointer-events: none;
  margin-top: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

